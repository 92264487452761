/**
 * Checks if the parent data for a page matches the provided slug.
 * Throws an error if the parent data is missing or if it doesn't match the slug.
 *
 * @param {Object} page - The page object.
 * @param {string} parentSlug - The parent slug from the path.
 * @throws {Error} ParentNotFoundError if the parent data is missing or doesn't match the slug.
 */
import { formatHyphenatedString } from "~/utils/formatHyphenatedString";
import { createError } from "~/utils/errorHandling";

export function checkParentData(page, parentSlug) {
  /**
   * Page don't have parent data attributes but have the parent slug from path
   */
  if (!page.attributes?.parent && parentSlug) {
    throw createError(
      `ParentNotFoundError`,
      `Parent data for the page wasn't found in the Strapi backend.`
    );
  }

  /**
   * Page have parent data attributes but don't have the parent slug from path
   */
  if (page.attributes?.parent && !parentSlug) {
    throw createError(
      `ParentNotFoundError`,
      `Parent data for the page wasn't found in the Strapi backend.`
    );
  }

  /**
   * Page have parent data attributes and have the parent slug from path, compare if are the same
   */
  if (page?.attributes?.parent && parentSlug) {
    if (page.attributes.parent !== formatHyphenatedString(parentSlug)) {
      throw createError(
        "MismatchedParentError",
        `Parent data for the page doesn't match the provided slug.`
      );
    }
  }
}
