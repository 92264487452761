/**
 * Retrieves the value just before the last slug in a given path.
 *
 * @param path - The input path string.
 * @returns The value before the last slug in the path.
 */
export function extractValueBeforeLastSegment(path: string): string {
  let trimmedPath = path.trim(); // Trim whitespace from the path

  if (trimmedPath.endsWith("/")) {
    trimmedPath = trimmedPath.slice(0, -1); // Remove the trailing slash
  }

  const lastSlashIndex = trimmedPath.lastIndexOf("/");
  if (lastSlashIndex === -1) {
    return "";
  }
  const secondLastSlashIndex = trimmedPath.lastIndexOf("/", lastSlashIndex - 1);
  return trimmedPath.slice(secondLastSlashIndex + 1, lastSlashIndex);
}
